<template>
    <a :href="link" class="card_wpr">
        <div class="sub_card">
            <span><img :src="require(`@/assets/images/${img}.svg`)"></span>
            <div class="title">
                <h3>{{ title }}
                    <label :for="title" class="capsule_btn">
                        <input type="checkbox" :id="title" v-model="switchValue"  :true-value="1" :false-value="0" @change="toggleSubCard" hidden>
                        <div><span></span></div>
                    </label>
                </h3>
                <h5>{{title.replace('Status','')}} is {{switchValue ? 'enabled' : 'disabled'}} for this account</h5>
            </div>
        </div>
    </a>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: "Sub Card",

        data () {
            return {
                switchValue: 0,
            };
        },

        props:{
            title: String,
            img: String,
            link: String,
            updateKillSwitch: Function,
        },

        computed: mapState({
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            if (Object.keys(vm.user).length) {
                if (vm.title == 'Email Status') {
                    vm.switchValue = vm.user.email_status;
                } else if (vm.title == 'SMS Status') {
                    vm.switchValue   = vm.user.sms_status;
                }
            }
        },

        methods: {
            toggleSubCard () {
                const vm = this;
                vm.updateKillSwitch(vm.switchValue);
            }
        }
    }
</script>

<template>
    <a :href="link" class="card_wpr" @click="method ? method(title) : {}">
        <div class="sub_card">
            <span><img :src="require(`@/assets/images/${img}.svg`)"></span>
            <div class="title">
                <h3>
                    {{ title }}
                    <label :for="title" class="capsule_btn" v-if="onoff">
                        <input type="checkbox" :id="title" hidden>
                        <div><span></span></div>
                    </label>
                </h3>
                <h5 v-if="sub">{{ sub }}</h5>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: "Sub Card",

        props:{
            title: String,
            sub: String,
            img: String,
            onoff: {
                type: Boolean,
                default: false,
            },
            method: Function,
            link: String,
        },
    }
</script>

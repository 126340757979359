<template>
    <div class="inner_pages" id="admin_index">
        <!-- <inner-sidebar /> -->
        <div class="respective_content">
            <div class="home">
                <div class="section_card" v-if="!user.company_id || user.is_company_location">
                    <div class="card_header">
                        Account Settings
                    </div>
                    <ul>
                        <li v-if="!user.company_id || user.is_company_location"><sub-card :title="`${user.company_id ? 'Account Setting' : 'Primary Account'}`" sub="Configure company settings" img="profile" @click="accountPrimaryLocation = true" /></li>
                        <li v-if="!user.company_id"><sub-card title="Sub-accounts" sub="Manage additional accounts" img="sequence-alt" @click="accountSecondaryLocation = true" /></li>
                        <li v-if="!user.company_id"><sub-card title="Coaches" sub="Manage additional coaches" img="users" @click="accountUser = true" /></li>
                        <li v-if="!user.company_id"><sub-card title="White Label" sub="Hide Thrive Coach branding" img="trophy-alt" @click="accountWhiteLabel = true" /></li>
                        <li v-if="!user.company_id"><sub-card title="Billing" sub="Manage your subscription" img="billing" @click="openBillingSection('profile')" /></li>
                        <li v-if="!user.company_id"><sub-card title="Security" sub="Configure security settings" img="security" @click="openBillingSection('security')" /></li>
                        <!-- <li><sub-card title="Refer &amp; Earn" sub="Join our affiliate program!" img="gift" @click="referal = true" /></li> -->
                    </ul>
                </div>
                <div class="section_card" v-if="!user.company_id || user.is_company_location">
                    <div class="card_header">
                        Affiliate Program
                    </div>
                    <ul>
                        <li><sub-card title="Refer &amp; Earn" sub="Get paid for referring other coaches" img="gift" @click="referal = true" /></li>
                    </ul>
                </div>
                <div class="section_card">
                    <div class="card_header">
                        Client ID's
                    </div>
                    <ul>
                        <li><sub-card title="Client ID Sign-Up Page" sub="Easy client sign-up page" img="template-alt" @click="memberIdDashboard = true" /></li>
                        <li><sub-card title="Client ID List" sub="See all client ID's at a glance" img="member-id" @click="memberIdList = true" /></li>
                    </ul>
                </div>
                <div class="section_card">
                    <div class="card_header">
                        Reports &amp; Schedules
                    </div>
                    <ul>
                        <li><sub-card title="Email Reports" sub="See global email activity" img="email" @click="reportEmail = true" /></li>
                        <li><sub-card title="Activity Feed" sub="See global contact activity" img="activity" @click="reportActivity = true" /></li>
                        <li><sub-card title="Schedules" sub="See email and SMS schedules" img="calendar-alt" @click="reportSchedule = true" /></li>
                    </ul>
                </div>
                <div class="section_card" v-if="!user.company_id || user.is_company_location">
                    <div class="card_header">
                        Integrations
                    </div>
                    <ul>
                        <li v-if="companyUserCan('twilio', 'integrations')"><sub-card title="Twilio" sub="Configure SMS sending" img="twilio" @click="integrationTwilio = true" /></li>
                        <!-- <li v-if="companyUserCan('unbounce', 'integrations')"><sub-card title="Unbounce" img="icon11" @click="integrationUnbounce = true" /></li> -->
                        <li v-if="companyUserCan('fit_funnels', 'integrations')"><sub-card title="Thrive Funnels" sub="Legacy integration" img="thrive-funnel" @click="integrationFitFunnels = true" /></li>
                        <li v-if="companyUserCan('mindbody', 'integrations')"><sub-card title="Mindbody" sub="Merge contacts with Mindbody" img="mindbody" @click="integrationMindbody = true" /></li>
                        <!-- <li v-if="companyUserCan('zen_planner', 'integrations')"><sub-card title="Zen Planner" img="icon14" @click="integrationZenPlanner = true" /></li> -->
                        <li v-if="companyUserCan('zapier', 'integrations')"><sub-card title="Zapier" sub="Connect to thousands of apps" img="zapier" @click="integrationZapier = true" /></li>
                    </ul>
                </div>
                <div class="section_card">
                    <div class="card_header">
                        Rewards
                    </div>
                    <ul>
                        <li><sub-card title="Rewards" sub="Celebrate positive behaviors" img="diamond" @click="rewardPointName = true" /></li>
                        <li><sub-card title="Rewards Currency" sub="Your rewards currency" img="currency-alt" @click="rewardCurrency = true" /></li>
                        <li><sub-card title="Notifications" sub="Configure rewards notifications" img="bell" @click="rewardNotification = true" /></li>
                    </ul>
                </div>
                <div class="section_card">
                    <div class="card_header">
                        Birthdays
                    </div>
                    <ul>
                        <li><sub-card title="Birthday Settings" img="calendar-alt" sub="Celebrate client birthdays" @click="birthdaySetting = true"/></li>
                        <li><sub-card title="Birthday List" img="calendar-alt" sub="See upcoming birthdays" @click="birthdayList = true" /></li>
                    </ul>
                </div>
                <div class="section_card" v-if="companyUserCan('view', 'contacts')">
                    <div class="card_header">
                        Contacts
                    </div>
                    <ul>
                        <li v-if="companyUserCan('add', 'contacts')"><sub-card title="Add Contacts" sub="Import contacts into your account" img="add-user" @click="contactAdd = true" /></li>
                        <li v-if="companyUserCan('view', 'contacts')"><sub-card title="Segments" sub="Group your contacts by common themes" img="segment-alt" @click="contactTab = true" /></li>
                        <li v-if="companyUserCan('view', 'contacts')"><sub-card title="Custom Fields" sub="Add additional fields to form fills" img="custom" @click="contactCustomField = true" /></li>
                        <li v-if="companyUserCan('view', 'contacts')"><sub-card title="Deleted Contacts" sub="View deleted contacts" img="deleted" @click="contactDeleteField = true" /></li>
                    </ul>
                </div>
                <div class="section_card">
                    <div class="card_header">
                        Content Settings
                    </div>
                    <ul>
                        <li><sub-card title="Buttons" sub="Create insertable content buttons" img="button" @click="contentSettingButton = true"/></li>
                        <li><sub-card title="Saved Styling" sub="Create default styling for your assets" img="brush" @click="contentSettingSavedStyle = true"/></li>
                    </ul>
                </div>
                <div class="section_card">
                    <div class="card_header">
                        Email Settings
                    </div>
                    <ul>
                        <!-- <li><sub-card title="Confirmed Opt In" img="icon34" @click="emailSettingConfirmedOptIn = true" /></li> -->
                        <li><sub-card title="Signatures" sub="Add unlimited email signatures" img="signature" @click="emailSettingSignature = true" /></li>
                        <li><sub-card title="Recovery Page" sub="Where contacts can resubscribe" img="recovery-page" @click="emailSettingRecoveryPage = true"/></li>
                        <li><sub-card title="Suppression List" sub="See contacts who unsubscribed" img="suppression-email" @click="emailSettingSuppressionList = true"/></li>
                        <li v-if="!user.company_id || user.is_company_location"><sub-card title="Email Domain" sub="Configure a custom sending address" img="globe" @click="emailSettingDomain = true" /></li>
                        <!-- <li><sub-card title="Deleted Contacts" sub="See your deleted contact details" img="contact-minus"/></li> -->
                    </ul>
                </div>
                <div class="section_card" v-if="!user.company_id || user.is_company_location">
                    <div class="card_header">
                        Kill Switch
                    </div>
                    <ul>
                        <li><sub-card-switch title="Email Status" img="email"  :update-kill-switch="handleKillEmail" ref="switch-email" /></li>
                        <li><sub-card-switch title="SMS Status" img="sms-circle" :update-kill-switch="handleKillSms" ref="switch-sms" /></li>
                    </ul>
                </div>

                <primary-location v-model="accountPrimaryLocation" v-if="loadContent" />
                <secondary-location v-model="accountSecondaryLocation" v-if="loadContent" />
                <user-component v-model="accountUser" v-if="loadContent" />
                <white-label v-model="accountWhiteLabel" v-if="loadContent" />
                <refer-earn v-model="referal" v-if="loadContent" />
                <contact-add-wizard v-model="contactAdd" v-if="loadContent" />
                <status-tabs-modal v-model="contactTab" v-if="loadContent" />
                <signature v-model="emailSettingSignature" v-if="loadContent" />
                <id-dashboard v-model="memberIdDashboard" v-if="loadContent" />
                <id-list v-model="memberIdList" v-if="loadContent" />
                <email-report v-model="reportEmail" v-if="loadContent" />
                <activity-feed v-model="reportActivity" v-if="loadContent" />
                <integration-twilio v-model="integrationTwilio" v-if="loadContent" />
                <integration-unbounce v-model="integrationUnbounce" v-if="loadContent" />
                <integration-fit-funnel v-model="integrationFitFunnels" v-if="loadContent" />
                <integration-mindbody v-model="integrationMindbody" v-if="loadContent" />
                <integration-zen-planner v-model="integrationZenPlanner" v-if="loadContent" />
                <integration-zapier v-model="integrationZapier" v-if="loadContent" />
                <contact-custom-field v-model="contactCustomField" v-if="loadContent" />
                <suppression-list v-model="emailSettingSuppressionList" v-if="loadContent" />
                <email-domain v-model="emailSettingDomain" v-if="loadContent" />
                <recovery-page v-model="emailSettingRecoveryPage" v-if="loadContent" />
                <saved-style v-model="contentSettingSavedStyle" v-if="loadContent" />
                <schedule-component v-model="reportSchedule" v-if="loadContent" />
                <point-name v-model="rewardPointName" v-if="loadContent" />
                <rewards-currency v-model="rewardCurrency" v-if="loadContent" />
                <notification-component v-model="rewardNotification" v-if="loadContent" />
                <birthday-setting v-model="birthdaySetting" v-if="loadContent" />
                <birthday-list v-model="birthdayList" v-if="loadContent" />
                <button-component v-model="contentSettingButton" v-if="loadContent" />
                <confirmed-opt-in v-model="emailSettingConfirmedOptIn" v-if="loadContent" />
                <deleted-contact v-model="contactDeleteField" v-if="loadContent" />
            </div>
        </div>
    </div>
</template>

<script>
    import SubCard from '@/components/SubCard'
    import SubCardSwitch from '@/pages/admin-setting/SubCardSwitch'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    export default {
        name: "Admin Setting",

        data () {
            return {
                accountPrimaryLocation: false,
                accountSecondaryLocation: false,
                accountUser: false,
                accountWhiteLabel: false,
                referal: false,
                memberIdDashboard: false,
                memberIdList: false,
                reportEmail: false,
                reportActivity: false,
                reportSchedule: false,
                integrationTwilio: false,
                integrationUnbounce: false,
                integrationFitFunnels: false,
                integrationMindbody: false,
                integrationZenPlanner: false,
                integrationZapier: false,
                rewardPointName: false,
                rewardCurrency: false,
                rewardNotification: false,
                birthdaySetting: false,
                birthdayList: false,
                contactAdd: false,
                contactTab: false,
                contactCustomField: false,
                contentSettingButton: false,
                contentSettingSavedStyle: false,
                emailSettingSignature: false,
                emailSettingRecoveryPage: false,
                emailSettingSuppressionList: false,
                emailSettingDomain: false,
                loadContent: false,
                emailSettingConfirmedOptIn: false,
                contactDeleteField: false,
            }
        },

        components: {
            SubCard,
            SubCardSwitch,
            PrimaryLocation: defineAsyncComponent(() => import('@/pages/admin-setting/components/account/PrimaryLocation')),
            SecondaryLocation: defineAsyncComponent(() => import('@/pages/admin-setting/components/account/SecondaryLocation')),
            UserComponent: defineAsyncComponent(() => import('@/pages/admin-setting/components/account/User')),
            WhiteLabel: defineAsyncComponent(() => import('@/pages/admin-setting/components/account/WhiteLabel')),
            ReferEarn: defineAsyncComponent(() => import('@/pages/admin-setting/components/account/ReferEarn')),
            ContactAddWizard: defineAsyncComponent(() => import('@/pages/contact/components/AddWizard')),
            StatusTabsModal: defineAsyncComponent(() => import('@/pages/contact/components/StatusTabsModal')),
            Signature: defineAsyncComponent(() => import('@/pages/admin-setting/components/signature/Signature')),
            IdDashboard: defineAsyncComponent(() => import('@/pages/admin-setting/components/member-id/IdDashboard')),
            IdList: defineAsyncComponent(() => import('@/pages/admin-setting/components/member-id/IdList')),
            EmailReport: defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/EmailReport')),
            ActivityFeed: defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/ActivityFeed')),
            IntegrationTwilio: defineAsyncComponent(() => import('@/pages/admin-setting/components/integration/Twilio')),
            IntegrationUnbounce: defineAsyncComponent(() => import('@/pages/admin-setting/components/integration/Unbounce')),
            IntegrationFitFunnel: defineAsyncComponent(() => import('@/pages/admin-setting/components/integration/FitFunnel')),
            IntegrationMindbody: defineAsyncComponent(() => import('@/pages/admin-setting/components/integration/Mindbody')),
            IntegrationZenPlanner: defineAsyncComponent(() => import('@/pages/admin-setting/components/integration/ZenPlanner')),
            IntegrationZapier: defineAsyncComponent(() => import('@/pages/admin-setting/components/integration/Zapier')),
            ContactCustomField: defineAsyncComponent(() => import('@/pages/admin-setting/components/contact/CustomField')),
            SuppressionList: defineAsyncComponent(() => import('@/pages/admin-setting/components/email/SuppressionList')),
            EmailDomain: defineAsyncComponent(() => import('@/pages/admin-setting/components/email/Domain')),
            RecoveryPage: defineAsyncComponent(() => import('@/pages/admin-setting/components/email/RecoveryPage')),
            SavedStyle: defineAsyncComponent(() => import('@/pages/admin-setting/components/content-setting/SavedStyle')),
            ScheduleComponent: defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/Schedule')),
            PointName: defineAsyncComponent(() => import('@/pages/admin-setting/components/reward/PointName')),
            RewardsCurrency: defineAsyncComponent(() => import('@/pages/admin-setting/components/reward/RewardsCurrency')),
            NotificationComponent: defineAsyncComponent(() => import('@/pages/admin-setting/components/reward/Notification')),
            BirthdaySetting: defineAsyncComponent(() => import('@/pages/admin-setting/components/birthday/BirthdaySetting')),
            BirthdayList: defineAsyncComponent(() => import('@/pages/admin-setting/components/birthday/BirthdayList')),
            ButtonComponent: defineAsyncComponent(() => import('@/pages/admin-setting/components/content-setting/Button')),
            ConfirmedOptIn: defineAsyncComponent(() => import('@/pages/admin-setting/components/email/ConfirmedOptIn')),
            DeletedContact: defineAsyncComponent(() => import('@/pages/admin-setting/components/contact/DeletedContact')),
        },

        watch: {
            user (user) {
                if (user) {
                    const vm = this;

                    const emailSwitch = vm.$refs['switch-email'];
                    const smsSwitch = vm.$refs['switch-sms'];

                    if (emailSwitch) {
                        emailSwitch.switchValue = user.email_status;
                    }

                    if (smsSwitch) {
                        smsSwitch.switchValue   = user.sms_status;
                    }
                }
            },

            accountPrimaryLocation (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            accountSecondaryLocation (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            accountUser (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            accountWhiteLabel (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            referal (val) {
                if(val){
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            memberIdDashboard (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            memberIdList (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            reportEmail (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            reportActivity (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            reportSchedule (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            integrationTwilio (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            integrationUnbounce (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            integrationFitFunnels (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            integrationMindbody (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            integrationZenPlanner (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            integrationZapier (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            rewardPointName (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            rewardCurrency (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            rewardNotification (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            birthdaySetting (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            birthdayList (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contactAdd (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contactTab (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contactCustomField (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contentSettingButton (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contentSettingSavedStyle (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            emailSettingSignature (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            emailSettingRecoveryPage (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            emailSettingSuppressionList (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            emailSettingDomain (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contactDeleteField (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                subscription: state => state.billingModule.subscription,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;
            vm.loadContent = false;

            setTimeout(function () {
                vm.loadContent = true;
            }, 1000);

            if (vm.$route.query && vm.$route.query.module == 'billing') {
                vm.openBillingSection(vm.$route.query.tab);
            }

            if (!vm.subscription.id) {
                // vm.getSubscription();
            }

            vm.getPlans();

            if(vm.$route.params.modal){
                vm[vm.$route.params.modal] = true;
            }
        },

        methods:{
            ...mapActions({
                updateKillStatus: 'authModule/updateKillStatus',
                refreshAuth: 'authModule/refreshAuth',
                getSubscription: 'billingModule/getSubscription',
                getPlans: 'billingModule/getPlans',
            }),

            handleKillEmail (val) {
                const vm = this;
                const emailSwitch = vm.$refs['switch-email'];

                if (val == 0) {
                    const option = Helper.swalConfirmOptions('Are you sure?', "Switching off your Email will disable and cancel all your automated Email sending. You will still be able to send Email manually. Are you sure you want to STOP all your automated Emails?", 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (result.isConfirmed) {
                            vm.killEmailStatus = 0;
                            emailSwitch.switchValue = 0;
                            vm.updateKillStatus({ type: 'email', status: 0 });
                        } else {
                            vm.killEmailStatus = 1;
                            emailSwitch.switchValue = 1;
                        }
                    });
                } else {
                    vm.updateKillStatus({ type: 'email', status: 1 });
                    vm.killEmailStatus = 1;
                    emailSwitch.switchValue = 1;
                }
            },

            handleKillSms (val) {
                const vm = this;
                const smsSwitch = vm.$refs['switch-sms'];

                if (val == 0) {
                    const option = Helper.swalConfirmOptions('Are you sure?', "Switching off your SMS will disable and cancel all your automated SMS sending. You will still be able to send SMS manually. Are you sure you want to STOP all your automated SMS?", 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (result.isConfirmed) {
                            vm.killSmsStatus = 0;
                            smsSwitch.switchValue = 0;
                            vm.updateKillStatus({ type: 'sms', status: 0 });
                        } else {
                            vm.killSmsStatus = 1;
                            smsSwitch.switchValue = 1;
                        }
                    });
                } else {
                    vm.updateKillStatus({ type: 'sms', status: 1 });
                    smsSwitch.switchValue = 1;
                }
            },

            openBillingSection (tab) {
                const vm = this;

                vm.$router.push({ name: 'AccountBilling', query: { tab } });
            },
        }
    }
</script>

<style scoped>
    #admin_index{
        max-width: 1200px;
        margin: 0 auto;
    }
    :deep(.custDomain_info) {
        padding: 15px 20px;
        /* background: rgba(47, 126, 237, 0.03); */
        background: #fff;
        border-radius: 5px;
        border: 1px solid rgba(47, 126, 237, 0.08);
    }

    :deep(.custDomain_info p) {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 10px;
        color: #5a5a5a;
    }

    :deep(.custDomain_info h5) {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        padding: 5px 0;
    }

    :deep(.custDomain_info ul) {
        padding: 10px 0 20px 0;
    }

    :deep(.custDomain_info ul li) {
        padding: 4px 0 4px 25px;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        position: relative;
    }

    :deep(.custDomain_info ul li i) {
        font-size: 11px;
        position: absolute;
        left: 5px;
        top: 7px;
        color: #2f7eed;
    }

    :deep(.result_wpr.small .actions > ul) {
        height: 31px;
        width: 100%;
    }

    :deep(.result_wpr.small .actions > ul li .dropdown_wpr) {
        width: 100%;
    }

    :deep(.result_wpr.small table td) {
        padding: 14px 10px;
        font-size: 11px;
        line-height: 14px;
    }

    :deep(.result_wpr.small table th) {
        padding: 8px 10px;
        font-size: 11px;
        line-height: 15px;
    }

    :deep(.result_wpr.small table td .user_img),
    :deep(.result_wpr.small table td .user_img img) {
        width: 30px;
        height: 30px;
    }

    :deep(.result_wpr.small table td h4) {
        font-size: 11px;
        line-height: 13px;
        color: #2f7eed;
        font-weight: 500;
    }

    :deep(.result_wpr table.standard td:first-child),
    :deep(.result_wpr table.standard th:first-child) {
        width: auto;
        text-align: left;
    }

    :deep(.result_wpr.small table.standard td.action) {
        width: 30px;
    }

    :deep(.result_wpr.small table.standard td.action i) {
        width: auto;
        font-size: 13px;
    }

    :deep(.result_wpr.small table.standard td .drp_wrapper),
    :deep(.tempcard ul li .drp_wrapper) {
        display: none;
    }

    :deep(.result_wpr.small table.standard td .drp_wrapper.active),
    :deep(.tempcard ul li .drp_wrapper.active) {
        display: block;
    }
</style>
